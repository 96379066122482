import * as types from "../actions/actiontypes"
import { LOAD_VOR_APPLICATIONS } from "./actiontypes";

const initialState = {
    customers:[],
    edgePCs:[],
    selectedEdgePC:null,
    addOrEditError:null,
    addOrEditSuccess:null,
    selectedCustomer:null,
    selectedDistributionCenter:null,
    tandems:[],
    selectedTandem:null,
    applicationList:[],
    edgeSerials:[],
    error: null,
    tendemError:null
}
const edgePCSReducers = function(state = initialState, action){
    const newState = { ...state };
    switch (action.type){
        case types.LOAD_VOR_APPLICATIONS_SUCCESS_TYPE:
            newState.applicationList = action.payload.data.Data;
            break;
        case types.SAVE_CORRECT_PALLET_SUCCESS:
            newState.palletstatus = action.payload.pallet;
            newState.palletdata = "reset";
            break;

        case types.GET_PALLET_SUCCESS:
            if(action.payload.pallet && action.payload.pallet.data && action.payload.pallet.data.Data){
                newState.palletdata = action.payload.pallet.data.Data;
            }else{
                newState.palletdata = "data";
            }

            break;
        case types.RESET_DATA:
            newState.palletdata = null;
            newState.palletstatus = null;
            break;
        case types.LOAD_CUSTOMERS_WITH_DC_SUCCESS:
            newState.customers = action.payload.customers.data.Data;
            break;
        case types.LOAD_EDGEPCS_WITH_SUCCESS:
            newState.edgePCs = action.payload.edgePCs.data.Data
            break;
        case types.LOAD_EDGEPCS_WITH_ERROR:
            newState.edgePCs = [];
            newState.error = errorDescription(action.payload.error);
            break;
        case types.SELECT_EDGEPC:
            newState.selectedEdgePC = action.payload.edgePC;
            newState.addOrEditSuccess = false;
            break;
        case types.ADD_EDGE_PC_WITH_SUCCESS:
            newState.addOrEditSuccess = true;
            newState.selectedEdgePC = null;
            break;
        case types.EDIT_EDGE_PC_WITH_SUCCESS:
            newState.addOrEditSuccess = true;
            newState.selectedEdgePC = null;
            break;
        case types.SELECT_DISTRIBUTION_CENTER:
            newState.selectedDistributionCenter = action.payload.distributionCenter;
            break;
        case types.SELECT_CUSTOMER:
            newState.selectedCustomer = action.payload.customer;
            break;
        case types.LOAD_TANDEMS_WITH_SUCCESS:
            newState.tandems = action.payload.tandems.data.Data;
            break;
        case types.LOAD_TANDEMS_WITH_ERROR:
            newState.tandems =[];
            newState.tendemError = errorDescription(action.payload.error);
            break;
        case types.SELECT_TANDEM:
            newState.selectedTandem = action.payload.tandem;
            newState.addOrEditSuccess = false;
            break;
        case types.ADD_TANDEM_WITH_SUCCESS:
            newState.addOrEditSuccess = true;
            newState.selectedTandem = null;
            break;
        case types.EDIT_TANDEM_WITH_SUCCESS:
            newState.addOrEditSuccess = true;
            newState.selectedTandem = null;
            break;
        case types.LOAD_EDGE_SERIALS_WITH_SUCCESS:
            newState.edgeSerials = action.payload.edgeSerials.data.Data;
            break;
        case types.LOAD_EDGE_SERIALS_WITH_ERROR:
            newState.edgeSerials = [];
            break;

    }
    return newState;
}
const errorDescription = (error) => {
    if (error != undefined && error.response != null) {
        if (error.response.data != null, error.response.data != undefined) {
            if (error.response.data.Error) {
                return error.response.data.Error;
            } else {
                return error.response.data
            }
        }
    } else {
        return error
    }
}
export default edgePCSReducers;
