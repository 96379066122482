import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux'
//import EdgePCList from './edgepcs/List'
//import TandemList from './Tandem/List'
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import * as EdgePCActions from '../../actions/edgepcs';
import { Auth } from 'aws-amplify';

import {
    Tabs,
    Tab,
    Box,
    Autocomplete,
    TextField,
    Grid,
    Paper,
    Button,
    Stack,
    Toolbar,
    Chip,
    FormGroup, Checkbox
} from '@mui/material'

import { select } from 'redux-saga/effects';
import FormControlLabel from "@mui/material/FormControlLabel";
import {RESET_DATA, SAVE_CORRECT_PALLET} from "../../actions/edgepcs";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`edgepcs-tabpanel-${index}`}
            aria-labelledby={`edgepcs-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

let customerId = "";
let dcId = "";
let status = "";
let operation = ""
let SUBMIT = "SUBMIT"
let GET_VALUES = "GET_VALUES"
let indicator = false

const CorrectPalletImage = (props) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [indicatorOn, setIndicatorOn] = useState(false);
    const [customers, setCustomers] = useState([])
    const [tendemError, setTendemError] = useState(null);
    const [edgepcError, setEdgepcError] = useState(null);
    const [edgePCS, setEdgePCS] = useState(null);
    const [tendems, setTendems] = useState(null);
    const [customer,setCustomer] = useState("");
    const [dc,setDc] = useState("");
    const [rand,setRand] = useState(0)

    const [checked,setChecked] = useState(false)

    useEffect(() => {
        Auth.currentSession().then(res => {
            let accessToken = res.getAccessToken();
            let jwt = accessToken.getJwtToken();
            localStorage.setItem('authToken', jwt);
            props.fetchCustomersWithDC();
        })
    }, []);


    useEffect(() => {
        console.log(props.palletstatus)
        console.log(props.palletdata)

        if(operation == GET_VALUES){
            if(props.palletdata != ""){
                setChecked(false)
                if(props.palletdata == "ACTIVE"){
                    setChecked(true)
                }
            }
        }
        indicator = false;
        if(props.palletdata != "data") {
            setIndicatorOn(false);
        }
       /* if (props.selectedDC && props.selectedDC.dc_id) {
            props.fetchEdgePC(props.selectedDC.dc_id);
            props.fetchTandems(props.selectedDC.dc_id);
        }*/
    }, [props.palletstatus,props.palletdata]);

    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#fff"
    });

    const handleCustomerChange = (_, option) => {
        console.log(option);
        customerId = "";
        dcId = ""
        if(option) {
            customerId = option.customer_id;
        }
        setChecked(false)
        props.selectCustomer(option);
        props.selectDistributionCenter(null);
        setDc(dcId)
        setCustomer(customerId)
    }

    const handleDCChange = (_, option) => {
        console.log(option);
        dcId = ""
        if(option){
            dcId = option.dc_id
            indicator = true;
            setIndicatorOn(true)
            operation = GET_VALUES;
            //props.resetData()
            props.getPaletStatus({type:"reset"})
            props.getPaletStatus({"custId":customerId,"dc_id":dcId,type:"values"})
        }

       // setIndicatorOn(true);
        setChecked(false)
        props.selectDistributionCenter(option);
        setDc(dcId)
    }

    let onSubmit = ()=>{
        console.log("Submit",customerId,dcId,checked)

        if(customerId && dcId){
            indicator = true
            setIndicatorOn(true)
            let data = {};
            data.customer_id = customerId
            data.dc_id = dcId
            data.status = checked?"ACTIVE":"INACTIVE";
            operation = SUBMIT;
            //setRand(Math.random()*100000000)
            props.getPaletStatus({type:"reset"})
            props.saveCorrectPallet(data)
        }


        /* setTimeout(()=>{
             console.log(props.addOrEditSuccess);
         },5000)*/




    }

    return (
        <div style={{ width: '100%' }}>
            <div>
                {edgepcError !== null ? <Alert severity="error" sx={{ width: '50%' }}>Erro in edgePC:{props.tendemError}</Alert> : ''}
                {tendemError !== null ? <Alert severity="error" sx={{ width: '50%' }}>Erro in Tendom: {props.errorResponse}</Alert> : ''}
                <div>
                    <h3 style={{ float: 'left' }}>Correct Pallet Image Feature</h3>
                </div>
                <div>
                    {indicatorOn ?
                        <div>
                            <Backdrop
                                sx={{ color: '#000', backgroundColor: 'rgb(255,255,255,0.7)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={indicatorOn}
                            >
                                <p>Please wait, while data is loading...</p> <CircularProgress color="inherit" />
                            </Backdrop>
                        </div> : ''
                    }
                    <Grid container spacing={2} style={{ marginBottom: '32px' }}>
                        <Grid item xs={3}>
                            <Autocomplete
                                disablePortal
                                id="customer1"
                                options={props.customers}
                                getOptionLabel={(option) => option.customer_name}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Customer" />}
                                onChange={handleCustomerChange}
                                value={props.selectedCustomer}
                            />
                        </Grid>
                        <Grid item xs={3} sx={{display:(customer?"":"none")}}>
                            <Autocomplete
                                disablePortal
                                id="distributionCenter1"
                                options={props.selectedCustomer ? props.selectedCustomer.dcs : []}
                                getOptionLabel={(option) => option.dc_name}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Distribution Center" />}
                                onChange={handleDCChange}
                                value={props.selectedDC}
                            />
                        </Grid>
                        <Grid item xs={2} sx={{display:(dc?"":"none")}}>
                                <Box>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={checked} onChange={()=>{
                                                    setChecked(!checked)
                                                }} sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }} name="gilad" />
                                            }
                                            label="Enabled"
                                        />
                                    </FormGroup>

                                </Box>

                        </Grid>
                        <Grid item xs={3} sx={{display:(dc?"":"none")}}>
                            <Box>
                                <Button autoFocus disabled={false}  variant="contained" onClick={onSubmit} sx={{width:"200px",height:"50px",color:"#FFF",fontWeight:"bold"}}>
                                    Submit
                                </Button>

                            </Box>
                        </Grid>

                    </Grid>

                </div>

            </div>

        </div>)
}
const mapStateToProps = (state) => {
    return {
        customers: state.edgePCs.customers ? state.edgePCs.customers : [],
        selectedDC: state.edgePCs.selectedDistributionCenter,
        selectedCustomer: state.edgePCs.selectedCustomer,
        errorResponse: state.edgePCs.error ? state.edgePCs.error : null,
        tendemError: state.edgePCs.tendemError ? state.edgePCs.tendemError : null,
        edgePCs: state.edgePCs.edgePCs,
        tandems: state.edgePCs.tandems,
        addOrEditSuccess:state.edgePCs.addOrEditSuccess,
        palletstatus:state.edgePCs.palletstatus,
        palletdata:state.edgePCs.palletdata
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchCustomersWithDC: () => dispatch(EdgePCActions.LOAD_CUSTOMERS_WITH_DC()),
        fetchEdgePC: (distributionId) => dispatch(EdgePCActions.LOAD_EDGEPCS(distributionId)),
        fetchTandems: (distributionId) => dispatch(EdgePCActions.LOAD_TANDEMS(distributionId)),
        selectCustomer: (customer) => dispatch(EdgePCActions.SELECT_CUSTOMER(customer)),
        saveCorrectPallet: (data) => dispatch(EdgePCActions.SAVE_CORRECT_PALLET(data)),
        getPaletStatus: (data) => dispatch(EdgePCActions.GET_PALLET_STATUS(data)),
        resetData: () => dispatch(EdgePCActions.RESET_DATA()),
        selectDistributionCenter: (distributionCenter) => dispatch(EdgePCActions.SELECT_DISTRIBUTION_CENTER(distributionCenter))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CorrectPalletImage);
